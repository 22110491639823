import React from 'react';


import SubblockWrapper from "./subblock-wrapper";
import ZertifizierungList from "../zertifizierung/zertifizierungList";

const SubblockZertifizierungList =({list}) => {

  // if list ist empty then nothing to display
  if (list?.length === 0) return <></>

  return (
    <>
      <SubblockWrapper fullwidth colorTheme={"light"} position="center" >
        <ZertifizierungList content={list || []} />
      </SubblockWrapper>
    </>
  );
}

export default SubblockZertifizierungList;