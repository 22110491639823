import React, {useState} from "react";
import {Col, Row} from "react-bootstrap";
import ZertifizierungItem from "./zertifizierungItem";
import {getSrc} from "gatsby-plugin-image";
import ImageViewer from "../image-viewer/image-viewer";

const ZertifizierungList= ({content}) => {

  const imageUrlList = content.map((item) => getSrc(item.picture.localFile));
  const [photoIndex,setPhotoIndex] = useState(0)

  return (
    <>
      <ImageViewer imageUrls={imageUrlList} photoIndex={photoIndex} setPhotoIndex={setPhotoIndex}>
        <Row className="justify-content-center">
          {content.map( (item,index) =>(
            <Col xs={12} lg={4} className="p-5" key={index} onClick={()=>setPhotoIndex(index)} onKeyDown={()=>setPhotoIndex(index)} tabIndex={0}>
              <ZertifizierungItem item={item}/>
            </Col>
          ))}
        </Row>
      </ImageViewer>
    </>
  );
}

export default ZertifizierungList