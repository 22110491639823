import React from 'react';
import {GatsbyImage, getImage} from "gatsby-plugin-image";


const ZertifizierungItem = ({ item }) => {
  const {title,subTitle, picture} = item

  return (
    <>
      <p className={"h4 text-secondary"}>{title}</p>
      <p className={"h4 text-secondary"}>{subTitle}</p>
      <h3 className={"hidden-but-visible-for-seo"}>{title} {subTitle}</h3>
      <GatsbyImage
        image={getImage(picture.localFile)}
        alt={picture.alternativeText}
        className="cursor-pointer"
      />
    </>
  );
}

export default ZertifizierungItem