import React from "react"
import { graphql } from "gatsby"
import Seo from "../components/seo";
import Layout from "../components/common/layout/layout";
import SubblockPageHeader from "../components/common/subblock/subblock-page-header";
import SubblockHeadlineWithSubtitle from "../components/common/subblock/subblock-headline-with-subtilte";
import SubblockZertifizierungList from "../components/common/subblock/subblock-zertifizierung-list";


const Zertifizierungen = ({ data }) => {
  const {header, title, zertifizierungList} = data.strapiZertifizierungen

  return (
    <>
      <Seo title="Zertifizierungen" />
      <Layout>
        <SubblockPageHeader content={header} />
        <SubblockHeadlineWithSubtitle title={title} noBottom />
        <SubblockZertifizierungList list={zertifizierungList} position="center" fullwidth />
      </Layout>
    </>
  )
}

export const pageQuery = graphql`
query strapiZertifizierungenQuery {
  strapiZertifizierungen {
    header {
      ...fragmentHeader
    }
    title
    zertifizierungList {
      ...fragmentZertifizierungList
    }
  }
}
`

export default Zertifizierungen


